import React, { useState } from 'react';
import Swal from 'sweetalert2'
// Define an array of investment plans


const InvestDeposit = () => {

    const investmentPlans = [
        {
          title: "STARTER PLAN 1",
          minDeposit: 20.0,
          maxDeposit: 1000.0,
          planDuration: 3,
          dailyProfit: "20%",
          profitReturn: 20,
          referralBonus: "5%",
          radio: 80,
        },
        {
          title: "CLASSIC PLAN 2",
          minDeposit: 2000.0,
          maxDeposit: 6000.0,
          planDuration: 5,
          dailyProfit: "30%",
          profitReturn: 30,
          referralBonus: "5%",
          radio: 81,
        },
        {
          title: "STOCKS PLAN 3",
          minDeposit: 7000.0,
          maxDeposit: 10000.0,
          planDuration: 7,
          dailyProfit: "50%",
          profitReturn: 50,
          referralBonus: "5%",
          radio: 82,
        },
        {
          title: "PROFESSIONAL PLAN",
          minDeposit: 20000.0,
          maxDeposit: 10000000000000000000000000,
          planDuration: 10,
          dailyProfit: "70%",
          profitReturn: 70,
          referralBonus: "5%",
          radio: 83,
        }
      ];
    const ListOfCoins = [

    ]
    const [Amount, setAmount] = useState(0)
    const [coinToUse, setcoinToUse] = useState(0)
    const [setselectedinvet, setsetselectedinvet] = useState(0)

  

    const processToInvestment = (investment,plan) => {

        if(Amount != 0 && coinToUse != 0){
            if (parseInt(Amount) >= plan.minDeposit && parseInt(Amount) <= plan.maxDeposit) {
                const baseUrl = '/CompleteDeposit';
                const params = new URLSearchParams();
                params.append('Invetmentplan', investment);
                params.append('Coin', coinToUse);
                params.append('amount', Amount);
                const completeDepositUrl = baseUrl + '?' + params.toString();
        
                global.window && (global.window.location.href = completeDepositUrl)
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'You selected the wrong investment for selected amount',
                  footer: '<a href="">Why do I have this issue?</a>'
                })
              }
        }else{
         alert("Fill IN THE REQUIRED FIELDS")
        }

    }

    return (
        <div className="row justify-content-center align-items-center">
            {investmentPlans.map((plan, index) => (
                <div className="col-md-3">
                    <div className="card-pricing2 card-primary">
                        <div className="pricing-header">
                            <h3 className="fw-bold">{plan.title}</h3>
                            <span className=" badge badge-light">Investment Plans</span>
                        </div>
                        <div className="price-value">
                            <div className="value">
                                <span className="currency" />
                                <span className="amount">{plan.profitReturn}<span>%</span></span>
                                {/* <span className="month">/Daily</span> */}
                            </div>
                        </div>
                        <ul className="pricing-content">
                            <li>Min Deposit <b className="float-right">${plan.minDeposit}</b></li>
                            <li>Max Deposit <b className="float-right">${plan.maxDeposit}</b></li>
                            <li>Daily Profit <b className="float-right">{plan.dailyProfit}%</b></li>
                            {/* <li>Referral Bonus <b className="float-right">{plan.referralBonus}%</b></li> */}
                            <li>Plan Duration <b className="float-right">{plan.planDuration} Day(s)</b></li>
                            <li>Profit withdrawal <b className="float-right">{plan.planDuration} Day(s)</b></li>
                            {/* <li>Capital Withdrawal <b className="float-right">{plan.planDuration*3} Day(s)</b></li>
                            <li>{plan.planDuration*24} Hours Withdrawal Payout</li>   */}
                            {/* <li>Profit Return <b>{plan.profitReturn}% After {plan.planDuration} Day(s)</b></li> */}
                        </ul>
                        <form className="forms-sample" action="deposit.html" method="post">
                            <div className="form-group">
                                <label className="form-control-label">Enter Amount: <span className="text-danger">*</span></label>
                                <input type="number" name="amount" className="form-control" onChange={(e) => { setAmount(e.target.value) }} />
                            </div>
                            <input type="hidden" name="plan" defaultValue={plan.radio} />
                            <div className="form-group">
                                <label className="form-control-label">Choose Payment Method: <span className="text-danger">*</span></label>
                                <div className="row">
                                    <div className="col-6" onClick={() => { setcoinToUse("BTC") }}>
                                        <input type="radio" className="myRadio" id={`myRadio1` + plan.radio} name="walletType" defaultValue="btc" />
                                        <label htmlFor={`myRadio1` + plan.radio} className="myLabel"><img src="../images/coins/s-btc.png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("Doge") }}>
                                        <input type="radio" className="myRadio" id={`myRadio2` + plan.radio} name="walletType" defaultValue="eth" />
                                        <label htmlFor={`myRadio2` + plan.radio} className="myLabel"><img src="https://img.icons8.com/?size=48&id=PjUpgs6o2IFx&format=png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("Tron") }}>
                                        <input type="radio" className="myRadio" id={`myRadio3` + plan.radio} name="walletType" defaultValue="ltc" />
                                        <label htmlFor={`myRadio3` + plan.radio} className="myLabel"><img src="https://img.icons8.com/?size=80&id=7NCvsu15urpd&format=png" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("ETH") }}>
                                        <input type="radio" className="myRadio" id={`myRadio7` + plan.radio} name="walletType" defaultValue="eth" />
                                        <label htmlFor={`myRadio7` + plan.radio} className="myLabel"><img src="../images/coins/b-xs-eth.png" /></label>
                                    </div>
                                    {/* https://coinremitter.com/assets/img/supported_currency/currency_usdterc20.png */}
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT ERC20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio4` + plan.radio} name="walletType" defaultValue="xrp" />
                                        <label htmlFor={`myRadio4` + plan.radio} className="myLabel"><img src="https://api.cryptocloud.plus/media//articles/img_1693302973.803533.jpg" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("USDT Trc20") }}>
                                        <input type="radio" className="myRadio" id={`myRadio5` + plan.radio} name="walletType" defaultValue="usdt" />
                                        <label htmlFor={`myRadio5` + plan.radio} className="myLabel"><img src="https://guarda.com/assets/images/academy/thumbs/usdt-trc.webp" /></label>
                                    </div>
                                    <div className="col-6" onClick={() => { setcoinToUse("WALLET") }}>
                                        <input type="radio" className="myRadio" id={`myRadio6` + plan.radio} name="walletType" defaultValue={1} />
                                        <label htmlFor={`myRadio6` + plan.radio} className="myLabel"><img src="../images/coins/s-bal.png" /></label>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary btn-lg w-75 fw-bold mb-3" name="invest" type="button" onClick={() => { processToInvestment(plan.title,plan) }}>Make Deposit</button>
                        </form>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InvestDeposit;
